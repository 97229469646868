import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "@/router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";

//VGT
import VueGoodTablePlugin from 'vue-good-table-next';
// import the styles 
import 'vue-good-table-next/dist/vue-good-table-next.css';

import { VueSelect } from "vue-select";

import vfmPlugin from 'vue-final-modal';

import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
import ContextMenu from '@imengyu/vue3-context-menu';

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus, {});
app.use(VueGoodTablePlugin);
app.use(vfmPlugin);
app.use(ContextMenu);
app.use(Toast, {});

app.component("v-select", VueSelect);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
