import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import neorisAdmin from "@/router/neoris-admin";
import { clinic, clinicMarketplaceOnly } from "@/router/clinic";

const routes: Array<RouteRecordRaw> = JwtService.isSuperAdmin()
  ? neorisAdmin
  : JwtService.isMarketplaceOnly()
    ? clinicMarketplaceOnly
    : clinic;

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // store.dispatch(Actions.VERIFY_AUTH);
  store.dispatch(Actions.VERIFY_AUTH).then(isLoggedIn => {
    if (to.name !== 'sign-in' && to.name !== 'password-reset' && to.name !== 'sign-up' && !isLoggedIn) {
      next({ name: 'sign-in' });
    } else {
      next();
    }
  });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
