export const clinic = [
	{
		path: "/",
		redirect: "/dashboard",
		component: () => import("@/layout/Layout.vue"),
		children: [
			{
				path: "/dashboard",
				name: "dashboard",
				component: () => import("@/views/Dashboard.vue"),
			},
			{
				path: "/account",
				name: "account",
				component: () => import("@/views/apps/users/Account.vue"),
			},
			{
				path: "/my-account",
				name: "my-account",
				component: () => import("@/views/crafted/account/Settings.vue"),
			},
			{
				path: "/emails",
				name: "emails",
				component: () => import("@/views/apps/email/Base.vue"),
				children: [
					{
						path: "",
						name: "inbox",
						component: () => import("@/views/apps/email/Inbox.vue"),
					},
					{
						path: "new",
						name: "new-email",
						component: () => import("@/views/apps/email/New.vue"),
					},
					{
						path: ":id",
						name: "single-email",
						component: () => import("@/views/apps/email/Email.vue"),
					},
				],
			},
			{
				path: "/operations",
				name: "operations",
				component: () => import("@/views/EmptyLayout.vue"),
				children: [
					{
						path: "",
						name: "operations-manager",
						component: () =>
							import("@/views/apps/operations/OperationsManager.vue"),
					},
					{
						path: ":id",
						name: "operation",
						component: () => import("@/views/apps/operations/Operation.vue"),
					},
				],
			},
			{
				path: "/calendar",
				name: "calendar",
				component: () => import("@/views/apps/operations/Calendar.vue"),
			},
			{
				path: "/marketplace",
				name: "marketplace",
				component: () => import("@/views/EmptyLayout.vue"),
				children: [
					{
						path: "",
						name: "products-manager",
						component: () =>
							import("@/views/apps/marketplace/ProductsManager.vue"),
					},
					{
						path: ":id",
						name: "product",
						component: () => import("@/views/apps/marketplace/Product.vue"),
					},
				],
			},
			{
				path: "/terms-and-conditions",
				name: "terms-and-conditions",
				component: () => import("@/views/apps/marketplace/TermsAndConditions.vue"),
			},
			{
				path: "/orders",
				name: "orders",
				component: () => import("@/views/apps/marketplace/Orders.vue"),
			},
			{
				path: "/invoices",
				name: "invoices",
				component: () => import("@/views/apps/marketplace/InvoicesManager.vue"),
			},
			{
				path: "/tickets",
				name: "tickets",
				component: () =>
					import("@/views/apps/tickets/TicketsManager.vue"),
			},
			{
				path: "/stocks",
				name: "stocks",
				component: () =>
					import("@/views/apps/stocks/StocksManager.vue"),
			},
			{
				path: "/users",
				name: "users",
				component: () =>
					import("@/views/apps/users/UsersManager.vue"),
			},
			{
				path: "/settings",
				name: "settings",
				component: () =>
					import("@/views/apps/settings/Settings.vue"),
			},
			{
				path: "/patients",
				name: "patients",
				component: () => import("@/views/EmptyLayout.vue"),
				children: [
					{
						path: "",
						name: "patients-manager",
						component: () =>
							import("@/views/apps/patients/PatientsManager.vue"),
					},
					{
						path: ":id",
						name: "patient",
						component: () => import("@/views/apps/patients/Patient.vue"),
					},
				],
			},
			{
				path: "/email-templates",
				name: "email-templates",
				component: () => import("@/views/EmptyLayout.vue"),
				children: [
					{
						path: "",
						name: "email-templates-manager",
						component: () =>
							import("@/views/apps/email-templates/EmailTemplatesManager.vue"),
					},
					{
						path: ":id",
						name: "email-template",
						component: () => import("@/views/apps/email-templates/EmailTemplate.vue"),
					},
					{
						path: "new",
						name: "new-email-template",
						component: () => import("@/views/apps/email-templates/EmailTemplate.vue"),
					},
				],
			},
		],
	},
	{
		path: "/",
		component: () => import("@/components/page-layouts/Auth.vue"),
		children: [
			{
				path: "/sign-in",
				name: "sign-in",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
			},
			{
				path: "/sign-up",
				name: "sign-up",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
			},
			{
				path: "/password-reset",
				name: "password-reset",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
			},
		],
	},
	{
		// the 404 route, when none of the above matches
		path: "/404",
		name: "404",
		component: () => import("@/views/crafted/authentication/Error404.vue"),
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404",
	},
];

export const clinicMarketplaceOnly = [
	{
		path: "/",
		redirect: "/marketplace",
		component: () => import("@/layout/Layout.vue"),
		children: [
			{
				path: "/marketplace",
				name: "marketplace",
				component: () => import("@/views/EmptyLayout.vue"),
				children: [
					{
						path: "",
						name: "products-manager",
						component: () =>
							import("@/views/apps/marketplace/ProductsManager.vue"),
					},
					{
						path: ":id",
						name: "product",
						component: () => import("@/views/apps/marketplace/Product.vue"),
					},
				],
			},
			{
				path: "/terms-and-conditions",
				name: "terms-and-conditions",
				component: () => import("@/views/apps/marketplace/TermsAndConditions.vue"),
			},
			{
				path: "/orders",
				name: "orders",
				component: () => import("@/views/apps/marketplace/Orders.vue"),
			},
			{
				path: "/invoices",
				name: "invoices",
				component: () => import("@/views/apps/marketplace/InvoicesManager.vue"),
			},
			{
				path: "/settings",
				name: "settings",
				component: () =>
					import("@/views/apps/settings/Settings.vue"),
			},
		],
	},
	{
		path: "/",
		component: () => import("@/components/page-layouts/Auth.vue"),
		children: [
			{
				path: "/sign-in",
				name: "sign-in",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
			},
			{
				path: "/sign-up",
				name: "sign-up",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
			},
			{
				path: "/password-reset",
				name: "password-reset",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
			},
		],
	},
	{
		// the 404 route, when none of the above matches
		path: "/404",
		name: "404",
		component: () => import("@/views/crafted/authentication/Error404.vue"),
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404",
	},
];
