const USER_TOKEN_KEY = "user" as string;
const ID_TOKEN_KEY = "id_token" as string;
const REFRESH_TOKEN_KEY = "refresh_token" as string;

/**
 * @description get user from localStorage
 */
 export const getUser = (): any | null => {
  const user = window.localStorage.getItem(USER_TOKEN_KEY);
  return user ? JSON.parse(user) : null;
};

/**
 * @description indicates whether the logged in user is a super admin or not
 */
 export const isSuperAdmin = (): boolean => {
   const user = getUser();
   return user ? user.superAdmin : false;
};

/**
 * @description indicates whether the logged in user has linked their Gmail account
 */
 export const hasGmailAccountLinked = (): boolean => {
  const user = getUser();
  if (!user) return false;
  return !!user?.clinic?.gmailApiToken && user?.clinic?.gmailApiToken?.access_token;
};

/**
 * @description indicates whether the logged in user belongs to a clinic that is restricted to the
 * marketplace (i.e. that has no access to all dashboard features with prospects/patients management)
 */
 export const isMarketplaceOnly = (): boolean => {
   const user = getUser();
   return user?.clinic?.accountType == "MARKETPLACE";
};

/**
 * @description get user's role from localStorage
 */
 export const getUserRole = (): any | null => {
   const user = getUser();
   return user ? user.role : null;
};

/**
 * @description save user in localStorage
 */
export const saveUser = (user: any): void => {
  window.localStorage.setItem(USER_TOKEN_KEY, JSON.stringify(user));
};

/**
 * @description remove user from localStorage
 */
 export const destroyUser = (): void => {
  window.localStorage.removeItem(USER_TOKEN_KEY);
};

/**
 * @description get token from localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token from localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

/**
 * @description get refresh token from localStorage
 */
 export const getRefreshToken = (): string | null => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

/**
 * @description save refresh token into localStorage
 * @param token: string
 */
export const saveRefreshToken = (token: string): void => {
  window.localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

/**
 * @description remove refresh token from localStorage
 */
export const destroyRefreshToken = (): void => {
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export default {
  getUser,
  isSuperAdmin,
  hasGmailAccountLinked,
  isMarketplaceOnly,
  getUserRole,
  saveUser,
  destroyUser,
  getToken,
  saveToken,
  destroyToken,
  getRefreshToken,
  saveRefreshToken,
  destroyRefreshToken,
};
