import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { useToast } from "vue-toastification";
import { Buffer } from "buffer";

const toast = useToast();

declare module 'axios' {
  export interface AxiosRequestConfig {
    config?: any;
  }
}

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  public static isAlreadyFetchingAccessToken: boolean;
  public static subscribers: Array<any>; // <=> Failed queue

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.subscribers = [];
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;


    ApiService.vueInstance.axios.interceptors.response.use(
      response => {
        const originalRequest = response.config;

        if (originalRequest.config && originalRequest.config.responseToast) {
          toast.success(originalRequest.config.responseToast.text, {
            timeout: 4000,
          });
        }

        return Promise.resolve(response);
      },
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        if (response.status === 401 && !originalRequest._retry
          && !originalRequest.url.includes('auth/refresh-tokens')
          && !originalRequest.url.includes('auth/reset-password')
          && !originalRequest.url.includes('auth/verify-email')
          && !originalRequest.url.includes('auth/login')) {
          if (!ApiService.isAlreadyFetchingAccessToken) {
            ApiService.isAlreadyFetchingAccessToken = true;
            const refreshToken = JwtService.getRefreshToken();
            ApiService.post("/auth/refresh-tokens", { refreshToken }).then(({ data }) => {
              ApiService.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              JwtService.saveToken(data.access.token);
              JwtService.saveRefreshToken(data.refresh.token);
              ApiService.setHeader();

              ApiService.subscribers = ApiService.subscribers.filter(callback => callback(data.access.token));
            });
          }

          const retryOriginalRequest = new Promise(resolve => {
            ApiService.subscribers.push((accessToken: string) => {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        } else if (response.status === 401 && originalRequest.url.includes('auth/refresh-tokens')) {
          window.location.href = "/sign-in";
        } else if (response.status === 413) {
          toast.error('Request data too large');
        } else if (response.data) {
          const parsedResData = (response.request.responseType === 'arraybuffer'
            && response.data.toString() === '[object ArrayBuffer]')
            ? JSON.parse(Buffer.from(response.data).toString('utf8'))
            : response.data;
          
          if (parsedResData.errors && Array.isArray(parsedResData.errors)) {
            toast.error(parsedResData.errors.join(', ') || 'An error has occured');
          } else {
            toast.error(parsedResData.message || 'An error has occured');
          }
        }

        return Promise.reject(error);
      }
    );

    this.setHeader();
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: any
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: any,
    config?: any,
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, { ...params, ...config });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string,
    params: any,
    config?: any,
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios
      .get(`${resource}/${slug}`, { params, config })
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: any
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    data: any,
    config?: any,
    params?: any,
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, data, { config, params });
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: any
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: any,
    config?: any,
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params, { config });
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: any
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: any,
    config?: any
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params, { config });
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string, config?: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource, { config }).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }

  public static fetchImageWithAuth(imageUrl: string): Promise<any> {
    return new Promise((resolve, reject) => {
        const headers = { method: 'GET', headers: { 'Authorization': `Bearer ${JwtService.getToken()}` } };
        return fetch(`${process.env.VUE_APP_API_URL}${imageUrl}`, headers)
            .then(response => response.blob()) // sending the blob response to the next then
            .then(blob => {
                const objectUrl = URL.createObjectURL(blob);
                resolve(objectUrl);
            }) // resolved the promise with the objectUrl 
            .catch(err => reject(err)); // if there are any errors reject them
    });
  }
}

export default ApiService;
